import http, { getAuthConfig } from "./http";

const BASE_URL = "/analisis-trainer";

const list = (params, callback) => {
	http
		.get(`${BASE_URL}/list${params}`, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const listExport = (params, callback) => {
	http
		.get(`${BASE_URL}/list-export${params}`, { responseType: "blob" })
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const recap = (params, callback) => {
	http
		.get(`${BASE_URL}/recap${params}`, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const recapAge = (params, callback) => {
	http
		.get(`${BASE_URL}/recap-age${params}`, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const recapAgency = (params, callback) => {
	http
		.get(`${BASE_URL}/recap-agency${params}`, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const recapTraining = (params, callback) => {
	http
		.get(`${BASE_URL}/recap-training${params}`, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const recapTrainingExport = (params, callback) => {
	http
		.get(`${BASE_URL}/recap-training-export${params}`, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const listNameTrainer = (params, callback) => {
	http
		.get(`${BASE_URL}/list-name-trainer${params}`, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const listNameAgency = (params, callback) => {
	http
		.get(`${BASE_URL}/list-name-agency${params}`, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_ANALISIS_TRAINER = {
	list,
	listExport,
	recap,
	recapAge,
	recapAgency,
	recapTraining,
	recapTrainingExport,
	listNameTrainer,
	listNameAgency,
};
